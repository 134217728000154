import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin--direct-import-institution-switches"
export default class extends Controller {
    declare switchMethodTarget: HTMLInputElement;
    declare percentageTarget: HTMLInputElement;
    declare percentageInputTarget: HTMLInputElement;
    declare createdBeforeDateTarget: HTMLInputElement;
    declare createdBeforeDateInputTarget: HTMLInputElement;

    static targets = ["switchMethod", "percentage", "percentageInput", "createdBeforeDate", "createdBeforeDateInput"];

    connect() {
        const selectedMethodRadioElement: HTMLInputElement = document.querySelector(
            `input[name="${this.switchMethodTarget.name}"]:checked`,
        );
        const percentageMethodSelected = selectedMethodRadioElement.value === "percentage";

        this.percentageTarget.hidden = !percentageMethodSelected;
        this.createdBeforeDateTarget.hidden = percentageMethodSelected;

        if (percentageMethodSelected) {
            this.focusOnPercentageInput();
        } else {
            this.focusOnCreatedBeforeDateInput();
        }
    }

    selectPercentage() {
        this.createdBeforeDateTarget.hidden = true;
        this.percentageTarget.hidden = false;
        this.focusOnPercentageInput();
    }

    selectCreatedBeforeDate() {
        this.percentageTarget.hidden = true;
        this.createdBeforeDateTarget.hidden = false;
        this.focusOnCreatedBeforeDateInput();
    }

    focusOnPercentageInput() {
        this.percentageInputTarget.focus();
        this.percentageInputTarget.setSelectionRange(-1, -1);
    }

    focusOnCreatedBeforeDateInput() {
        this.createdBeforeDateInputTarget.focus();
        this.createdBeforeDateInputTarget.setSelectionRange(-1, -1);
    }
}

import * as $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";
import { SSOAuthParams, AuthenticationProvider, SSORequestParameters, SignupServerResponse } from "./types";
import {
    hideAuthenticationSsoErrors,
    addErrorToField,
    hideFormErrors,
    showAuthenticationSsoError,
    genericServerErrorMessage,
    handleCommonAuthErrors,
    initAppleIdentity,
    initGoogleIdentity,
} from "ynab_api/authentication_shared";

export default class extends Controller {
    declare formTarget: HTMLFormElement;
    declare googleButtonTarget: HTMLFormElement;
    declare appleButtonTarget: HTMLFormElement;

    static targets = ["form", "appleButton", "googleButton"];

    public connect(): void {
        // eagerly loads scripts used for tracking signups
        window.YNABAnalytics?.loadSignupTracking();
    }

    public appleButtonTargetConnected(appleButton: HTMLFormElement): void {
        initAppleIdentity(appleButton, this.onSSOSuccess);
    }

    public googleButtonTargetConnected(googleButton: HTMLFormElement): void {
        initGoogleIdentity(googleButton, this.onSSOSuccess);
    }

    public formTargetConnected(signupForm: HTMLFormElement): void {
        ($(signupForm) as JQuery<HTMLFormElement>).validate();
    }

    public beforeSend(_event: CustomEvent) {
        hideAuthenticationSsoErrors();
    }

    public handleSuccess(event: CustomEvent) {
        requestAnimationFrame(() => Rails.disableElement(this.formTarget));
        this.trackSignup(AuthenticationProvider.Email, event.detail[0] as SignupServerResponse);
        this.redirect();
    }

    public handleError(event: CustomEvent) {
        const formTarget = $(this.formTarget) as JQuery<HTMLFormElement>;
        const [response, _status, xhr] = event.detail;

        hideFormErrors(formTarget);

        if (xhr.status === 401 || xhr.status === 422) {
            const error: { id: string; message: string } = response?.error;
            if (!error || !error.message) {
                return addErrorToField(formTarget, ".js-form-email", genericServerErrorMessage);
            } else if (/^password_/.test(error.id)) {
                return addErrorToField(formTarget, ".js-form-password", error.message);
            } else {
                return addErrorToField(formTarget, ".js-form-email", error.message);
            }
        } else {
            return handleCommonAuthErrors(formTarget, xhr);
        }
    }

    private attachSignUpAdditionalFormData(signupData: SSORequestParameters) {
        const retVal = $.extend({}, signupData);
        const hiddenRequestDataFields = $(this.formTarget)
            .find("input[type=hidden][name^=request_data]")
            .serializeArray();
        for (const field of hiddenRequestDataFields) {
            retVal[field.name] = field.value;
        }
        return retVal;
    }

    private onSSOSuccess = (authParams: SSOAuthParams, button: HTMLFormElement): void => {
        const ssoRequestData: SSORequestParameters = { request_data: authParams };

        const signUpFormData = this.attachSignUpAdditionalFormData(ssoRequestData);

        const requestSettings: JQueryAjaxSettings = {
            url: this.formTarget.getAttribute("action"),
            data: signUpFormData,
            dataType: "json",
            method: "POST",
        };

        $(button).prop("disabled", true);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        $.ajax(requestSettings)
            .then((signupResponse: SignupServerResponse) => {
                this.trackSignup(authParams.provider, signupResponse);
                this.redirect();
            })
            .fail((jqXHR: JQueryXHR) => {
                $(button).prop("disabled", false);
                this.handleSSOAuthFailure(authParams.provider, jqXHR);
            });
    };

    private handleSSOAuthFailure(provider: AuthenticationProvider, jqXHR: JQueryXHR) {
        const error = jqXHR.responseJSON != null ? jqXHR.responseJSON.error : undefined;

        if ((jqXHR.status === 401 || jqXHR.status === 422) && error) {
            switch (error.id) {
                case "email_blank":
                    if (provider === AuthenticationProvider.Apple) {
                        showAuthenticationSsoError(
                            AuthenticationProvider.Apple,
                            "Unfortunately we didn't receive an email from Apple. You'll need to disconnect YNAB from your Apple ID before trying again",
                        );
                    }
                    break;
                case "base_credential_connected":
                case "email_taken": {
                    const existingAccountError = `${error.message} <a href="/users/sign_in">Log in</a>?`;
                    return showAuthenticationSsoError(provider, existingAccountError);
                }
                default:
                    return showAuthenticationSsoError(provider, error.message);
            }
        } else {
            return showAuthenticationSsoError(provider, genericServerErrorMessage);
        }
    }

    private trackSignup(provider: AuthenticationProvider, signupResponse: SignupServerResponse) {
        if (window.YNABAnalytics != null) {
            window.YNABAnalytics.trackSignup(provider, signupResponse.id);
        }
    }

    private redirect() {
        const redirectURL = this.formTarget.dataset.redirectUrl ?? "";
        YNAB.redirectWithDelay(redirectURL);
    }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="admin--organization-resources"
export default class OrganizationResourcesController extends Controller {
    declare resourceableSelectTarget: HTMLSelectElement;
    static targets = ["resourceableSelect"];

    connect() {
        this.setResourceableFields();
    }

    handleResourceableChange(_event: Event) {
        this.setResourceableFields();
    }

    setResourceableFields() {
        const isTypeInfo = this.resourceableSelectTarget.value === "Groups::Resources::Info";
        this.setResourceableFieldsVisibility(".show-only-type-info", isTypeInfo);
        this.setResourceableFieldsVisibility(".show-only-type-report", !isTypeInfo);
    }

    setResourceableFieldsVisibility(sectionsSelector: string, visible: boolean) {
        // we want to show/hide the appropriate fields based on the resourceable type
        // we also need to disable the fields, so their values are not submitted
        document.querySelectorAll<HTMLElement>(sectionsSelector).forEach((section) => {
            section.hidden = !visible;
            section.querySelectorAll<HTMLInputElement>("input, select").forEach((input) => {
                input.disabled = !visible;
            });
        });
    }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="organization-edit"
export default class extends Controller {
    declare billableStatusTarget: HTMLSelectElement;
    declare initialValue: string;
    static targets = ["billableStatus"];

    readonly TERMINATED: string = "terminated";

    connect(): void {
        // store initial value so we only confirm termination if it is the change being made
        this.initialValue = this.billableStatusTarget.value;
    }

    confirmTermination(event: Event) {
        const confirmText =
            "Are you sure you want to terminate this group? All group members and membership candidates will be removed and their group membership data will be deleted. This action cannot be undone.";

        if (this.billableStatusTarget.value === this.TERMINATED && this.initialValue !== this.TERMINATED) {
            if (!confirm(confirmText)) {
                event.preventDefault();
            }
        }
    }
}

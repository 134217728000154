import {
    hideFormErrors,
    handleCommonAuthErrors,
    addErrorToField,
    genericServerErrorMessage,
} from "ynab_api/authentication_shared";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    declare initiateFormTarget: HTMLFormElement;
    declare passwordsContainerTarget: HTMLElement;
    declare passwordsSuccessContainerTarget: HTMLElement;
    declare emailInputTarget: HTMLFormElement;

    static targets: string[] = ["initiateForm", "passwordsContainer", "passwordsSuccessContainer", "emailInput"];

    public initiateFormTargetConnected(form: HTMLFormElement): void {
        const initiateForm = $(form) as JQuery<HTMLFormElement>;
        initiateForm.validate();
    }

    public handleInitiateSuccess(_event: CustomEvent): void {
        requestAnimationFrame(() => {
            Rails.disableElement(this.initiateFormTarget);
        });

        this.passwordsSuccessContainerTarget.querySelector(".js-email").textContent = this.emailInputTarget.value;

        $(this.passwordsContainerTarget).fadeOut("fast", () => {
            $(this.passwordsSuccessContainerTarget).fadeIn("fast");
        });
    }

    public handleInitiateError(event: CustomEvent): void {
        const formTarget = $(this.initiateFormTarget) as JQuery<HTMLFormElement>;
        const [response, _status, xhr] = event.detail;

        hideFormErrors(formTarget);

        const error: { id: string; message: string } = response?.error;
        if (xhr.status === 401 || xhr.status === 422) {
            if (error) {
                addErrorToField(formTarget, ".js-form-email", error.message);
            } else {
                addErrorToField(formTarget, ".js-form-email", genericServerErrorMessage);
            }
        } else {
            return handleCommonAuthErrors(formTarget, xhr);
        }
    }
}

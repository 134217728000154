import { Controller } from "@hotwired/stimulus";
import { hideFormErrors } from "ynab_api/authentication_shared";
import OTPFormController from "./otp_form_controller";

export default class extends Controller {
    declare destroyFormTarget: HTMLFormElement;
    static targets: string[] = ["destroyForm"];

    declare otpFormOutlet: OTPFormController;
    static outlets: string[] = ["otp-form"];

    public destroyFormTargetConnected(form: HTMLFormElement): void {
        ($(form) as JQuery<HTMLFormElement>).validate();
    }

    public otpFormOutletConnected(outlet: OTPFormController, _element: HTMLElement): void {
        outlet.show();
        outlet.onShowBackupHook = this.onShowOTPBackup.bind(this);
    }

    private onShowOTPBackup(): void {
        const newLabel = "<label>(This code was given to you when you first enabled two-step verification.)</label>";
        this.otpFormOutlet.labelTarget.insertAdjacentHTML("afterend", newLabel);
        const formTarget = $(this.destroyFormTarget) as JQuery<HTMLFormElement>;
        // only hide the errors when the user is clicking to backup, not when the page is refreshing on the backup page with an error
        if (!this.otpFormOutlet.backupCodeOnStart) {
            hideFormErrors(formTarget);
        }
    }
}

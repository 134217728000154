// For now, don't forget to add
// <% append_javascript_pack_tag("stimulus") %>
// to the view you want to use the controller in

import { application } from "./application";

import Admin__DirectImportInstitutionSwitchesController from "./admin/direct_import_institution_switches_controller";
application.register("admin--direct-import-institution-switches", Admin__DirectImportInstitutionSwitchesController);

import Admin__OrganizationResourcesController from "./admin/organization_resources_controller";
application.register("admin--organization-resources", Admin__OrganizationResourcesController);

import Admin__OrganizationEditController from "./admin/organization_edit_controller";
application.register("admin--organization-edit", Admin__OrganizationEditController);

import SignupController from "./signup_controller";
application.register("signup", SignupController);

import LoginController from "./login_controller";
application.register("login", LoginController);

import OTPFormController from "./otp_form_controller";
application.register("otp-form", OTPFormController);

import DestroyOTPController from "./destroy_otp_controller";
application.register("destroy-otp", DestroyOTPController);

import EditPasswordsController from "./edit_passwords_controller";
application.register("edit-passwords", EditPasswordsController);

import NewPasswordsController from "./new_passwords_controller";
application.register("new-passwords", NewPasswordsController);

import SetWellnessPasswordsController from "./set_wellness_passwords_controller";
application.register("set-wellness-passwords", SetWellnessPasswordsController);

import UserSettingsController from "./user_settings_controller";
application.register("user-settings", UserSettingsController);
